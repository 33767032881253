import { DataObject, Item, DataHandler, getDataObjectConfigById, getDataObjectById, RecordSource } from 'o365-dataobject';
import { DataObjectFileUpload } from 'o365-fileupload';
import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import { app, Procedure } from 'o365-modules';
import { FilterObject, DistinctHandler } from 'o365-filterobject';
import { ref, type Ref, markRaw } from 'vue';
import {
    BasePropertiesDataHandler,
    SelectableProperty,
    PropertiesData,
} from 'o365-data-properties';
import type {
    PropertyValueModel,
    PropertiesDefintion,
    PropertiesEditor
} from 'o365-data-properties';
import { OrgUnitsLookup, ObjectsLookup } from 'o365-offline-components';

import { logger, BulkOperation } from 'o365-utils';

import type { UploadOptions } from 'o365-fileupload';
import type { DataObjectWithProperites } from './DataObject.PropertiesData.ts';
import type { ItemModel, DataItemModel, RecordSourceOptions, IRequestOptions, RequestOperation } from 'o365-dataobject';
import type { AppState } from 'o365.pwa.types.ts';
import type { WhereExpression } from 'o365.pwa.modules.shared.dexie.WhereExpression.ts';

export type WhereExpressionOperator = 'equals' | 'greaterthan' | 'lessthan' | 'beginswith' | 'endswith' | 'contains' | 'contains_exact' | 'full_text' | 'isnull' | 'istrue' | 'inlist' | 'between' | 'like' | 'isblank' | 'notequals' | 'numbernotequals' | 'greaterthanorequal' | 'lessthanorequal' | 'notbeginswith' | 'notendswith' | 'notcontains' | 'isnotnull' | 'isfalse' | 'notinlist' | 'notbetween' | 'dateequals' | 'datebetween' | 'datenotequals' | 'datenotbetween' | 'dategreaterthan' | 'dategreaterthanorequal' | 'datelessthan' | 'datelessthanorequal' | 'timebetween' | 'timeequals' | 'timebefore' | 'timeafter' | 'notlike' | 'isnotblank';

export interface IWhereGroup {
    type: 'group';
    mode: 'and' | 'or';
    items: Array<WhereObject>,
}

export interface IWhereExpression {
    type: 'expression'
    operator: WhereExpressionOperator,
    column: string,
    valueType?: 'date' | 'datetime' | 'string',
    value: any,
}

export type WhereObject = IWhereGroup | IWhereExpression;

declare module 'o365-dataobject' {
    export interface DataObject {
        _shouldEnableOffline: boolean;
        shouldEnableOffline: boolean;
        _shouldGenerateOfflineData: boolean;
        shouldGenerateOfflineData: boolean;
        _jsonDataVersion: number;
        jsonDataVersion: number;
        _appIdOverride?: string;
        appIdOverride?: string;
        _databaseIdOverride?: string;
        databaseIdOverride?: string;
        _objectStoreIdOverride?: string;
        objectStoreIdOverride?: string;
        _generateOfflineDataViewNameOverride?: string;
        generateOfflineDataViewNameOverride?: string;
        _generateOfflineDataProcedureNameOverride?: string;
        generateOfflineDataProcedureNameOverride?: string;
        _propertiesDataObjectId?: string;
        propertiesDataObjectId?: string;
        _offline: DataObjectOffline;
        offline: DataObjectOffline;
        enableOffline: () => DataObjectOffline;
        _whereObject: WhereObject;
        whereObject: WhereObject;
        _indexedDbWhereExpression: WhereExpression;
        indexedDbWhereExpression: WhereExpression;
    }

    export interface RecordSource {

    }

    export interface Item {
        getFilePath: (mode: 'view' | 'download' | 'view-pdf' | 'download-pdf', options?: {
            viewName?: string,
            primKey?: string,
            primKeyColumnName?: string,
            fileName?: string,
            fileNameColumnName?: string,
            queryString?: string,
        }) => string;
    }
}

declare module 'o365-modules' {
    export interface IDataObjectConfig {
        enableOffline: boolean;
        generateOfflineData: boolean;
        jsonDataVersion: number;
        appIdOverride?: string;
        databaseIdOverride?: string;
        objectStoreIdOverride?: string;
    }

    export interface IDataObjectFieldConfig {
        pwaIsPrimaryKey?: boolean;
        pwaUseIndex?: boolean;
        pwaIsUnique?: boolean;
        pwaIsMultiValue?: boolean;
        pwaCompoundId?: number;
    }
}

declare module 'o365-fileupload' {
    export interface IFileUpload {
    }
    export interface IChunkUpload {

    }
}

declare module 'o365-filterobject' {
    export interface IFilterObject {
    }
}

type DataObjectOfflineProperties = {
    shouldEnableOffline: boolean;
    offline: DataObjectOffline;
}

export interface IOfflineRequestOptions {
    skipAbortCheck?: boolean,
    appStateOverride?: AppState
}

export interface IDefaultOfflineRequestOptions {
    skipAbortCheck: boolean,
}

export interface IIndexedDBIndexConfig {
    id: string;
    keyPath: string | Array<string> | null;
    isPrimaryKey: boolean;
    isUnique: boolean;
    isMultiEntry: boolean;
    isAutoIncrement: boolean;
}

const defaultOfflineRequestOptions: IDefaultOfflineRequestOptions = {
    skipAbortCheck: false,
} as const;

Object.defineProperties(DataObject.prototype, {
    shouldEnableOffline: {
        get: function shouldEnableOffline(this: DataObject & DataObjectOfflineProperties): boolean {
            return this._shouldEnableOffline ??= getDataObjectConfigById(this.id, this.appId)?.offline?.enableOffline ?? false;
        },
        set: function shouldEnableOffline(this: DataObject & DataObjectOfflineProperties, value: boolean): void {
            this._shouldEnableOffline = value;
        }
    },
    shouldGenerateOfflineData: {
        get: function shouldGenerateOfflineData(this: DataObject & DataObjectOfflineProperties): boolean {
            return this._shouldGenerateOfflineData ??= getDataObjectConfigById(this.id, this.appId)?.offline?.generateOfflineData ?? false;
        },
        set: function shouldGenerateOfflineData(this: DataObject & DataObjectOfflineProperties, value: boolean): void {
            this._shouldGenerateOfflineData = value;
        }
    },
    jsonDataVersion: {
        get: function jsonDataVersion(this: DataObject & DataObjectOfflineProperties): number | undefined {
            return this._jsonDataVersion ??= getDataObjectConfigById(this.id, this.appId)?.offline?.jsonDataVersion ?? -1;
        },
        set: function jsonDataVersion(this: DataObject & DataObjectOfflineProperties, value: number): void {
            this._jsonDataVersion = value;
        }
    },
    appIdOverride: {
        get: function appIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            this._appIdOverride ??= getDataObjectConfigById(this.id, this.appId)?.offline?.appIdOverride;

            if (typeof this._appIdOverride !== 'string' || this._appIdOverride.trim().length === 0) {
                return undefined;
            }

            return this._appIdOverride.trim();
        },
        set: function appIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {
            this._appIdOverride = value;
        }
    },
    databaseIdOverride: {
        get: function databaseIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            this._databaseIdOverride ??= getDataObjectConfigById(this.id, this.appId)?.offline?.databaseIdOverride;

            if (typeof this._databaseIdOverride !== 'string' || this._databaseIdOverride.trim().length === 0) {
                return undefined;
            }

            return this._databaseIdOverride.trim();
        },
        set: function databaseIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {
            this._databaseIdOverride = value;
        }
    },
    objectStoreIdOverride: {
        get: function objectStoreIdOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            this._objectStoreIdOverride ??= getDataObjectConfigById(this.id, this.appId)?.offline?.objectStoreIdOverride;

            if (typeof this._objectStoreIdOverride !== 'string' || this._objectStoreIdOverride.trim().length === 0) {
                return undefined;
            }

            return this._objectStoreIdOverride.trim();
        },
        set: function objectStoreIdOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {
            this._objectStoreIdOverride = value;
        }
    },
    generateOfflineDataViewNameOverride: {
        get: function generateOfflineDataViewNameOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            return this._generateOfflineDataViewNameOverride ??= getDataObjectConfigById(this.id, this.appId)?.offline?.generateOfflineDataViewNameOverride;
        },
        set: function generateOfflineDataViewNameOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {
            this._generateOfflineDataViewNameOverride = value;
        }
    },
    generateOfflineDataProcedureNameOverride: {
        get: function generateOfflineDataProcedureNameOverride(this: DataObject & DataObjectOfflineProperties): string | undefined {
            return this._generateOfflineDataProcedureNameOverride ??= getDataObjectConfigById(this.id, this.appId)?.offline?.generateOfflineDataProcedureNameOverride;
        },
        set: function generateOfflineDataProcedureNameOverride(this: DataObject & DataObjectOfflineProperties, value: string): void {
            this._generateOfflineDataProcedureNameOverride = value;
        }
    },
    offline: {
        get: function offline(this: DataObject & DataObjectOfflineProperties): DataObjectOffline | null {
            if (this.shouldEnableOffline === false) {
                return null;
            }

            return this._offline ??= new DataObjectOffline(this);
        }
    },
    enableOffline: {
        value: function enableOffline(this: DataObject & DataObjectOfflineProperties) {
            return this.offline;
        }
    },
    whereObject: {
        get: function whereObject(this: DataObject & DataObjectOfflineProperties): WhereObject {
            return this._whereObject;
        },
        set: function whereObject(this: DataObject & DataObjectOfflineProperties, newValue: WhereObject): void {
            this._whereObject = newValue;
        }
    },
    indexedDbWhereExpression: {
        get: function indexedDbWhereExpression(this: DataObject & DataObjectOfflineProperties): WhereExpression {
            return this._indexedDbWhereExpression;
        },
        set: function indexedDbWhereExpression(this: DataObject & DataObjectOfflineProperties, newValue: WhereExpression): void {

            // console.log('Settings IndexedDbWhereExpression', newValue)
            this._indexedDbWhereExpression = newValue;
        }
    },
    useGroupedRequests: {
        get: function useGroupedRequests(this: DataObject & DataObjectOfflineProperties): boolean {
            return false;
        }
    }
});

Object.defineProperties(Procedure.prototype, {
    useGroupedRequests: {
        get: function useGroupedRequests(this: Procedure): boolean {
            return false;
        }
    }
});

const originalBulkRetrieve = RecordSource.prototype.bulkRetrieve;

Object.defineProperties(RecordSource.prototype, {
    bulkRetrieve: {
        get: function bulkRetrieve(this: RecordSource): Function {
            return async (pValues: (string | number)[], pField: string) => {
                const baseOptions = this.getOptions();
                const fields = [];

                if (baseOptions.fields) {
                    for (const field of baseOptions.fields) {
                        const parsedField = { ...field };
                        delete parsedField.sortDirection;
                        delete parsedField.sortOrder;
                        fields.push(parsedField);
                    }
                }

                const whereObject = baseOptions.whereObject;

                const options = {
                    fields: fields,
                    filterString: undefined,
                    whereObject: whereObject,
                    maxRecords: -1,
                    skip: 0
                };

                return await this.retrieve(options);
            }
        }
    }
});

const originalFileUpload = DataObjectFileUpload.prototype.upload;

Object.defineProperties(DataObjectFileUpload.prototype, {
    upload: {
        get: function upload(this: DataObjectFileUpload): Function {
            return async (pOptions: UploadOptions, pData: any) => {
                const dataObject = this.getDataObject();
                const dataObjectOffline: DataObjectOffline = dataObject.offline;


                const appIdOverride = dataObjectOffline?.appIdOverride ?? app.id;
                const databaseIdOverride = dataObjectOffline?.databaseIdOverride ?? "DEFAULT";
                const objectStoreIdOverride = dataObjectOffline?.objectStoreIdOverride ?? dataObject.id;

                dataObject.fileUpload.fileUpload.skipXhrHeader = true;

                if (dataObjectOffline === null || dataObjectOffline.appStateOverride === 'ONLINE') {
                    dataObject.fileUpload.fileUpload.skipXhrHeader = false;
                }
                pOptions.appId = appIdOverride;
                pOptions.chunkInitUrl = `/api/file/chunkupload/initiate/${appIdOverride}/${databaseIdOverride}/${objectStoreIdOverride}${pOptions.data && pOptions.data.values && pOptions.data.values.PrimKey ? '/' + pOptions.data.values.PrimKey : ''}`;
                return originalFileUpload.call(this, pOptions, pData);
            }
        }
    }
});

const originalsetFilterString = FilterObject.prototype.setFilterString;

Object.defineProperties(FilterObject.prototype, {
    setFilterString: {
        get: function setFilterString(this: FilterObject): Function {
            return (filterString: string): void => {
                if (this.dataObject.shouldEnableOffline) {
                    return;
                }

                return originalsetFilterString.call(this, filterString);
            };
        }
    }
});

const originalGetQueryOptions = DistinctHandler.prototype.getQueryOptions;
const originalGetData = DistinctHandler.prototype.getData;

Object.defineProperties(DistinctHandler.prototype, {
    getQueryOptions: {
        get: function getQueryOptions(this: DistinctHandler): Function {
            return (): any => {
                if (!this.dataObject.shouldEnableOffline) {
                    return originalGetQueryOptions();
                }

                if (this.dataObject == null) {
                    return null;
                }

                const options: Partial<RecordSourceOptions> = {
                    viewName: this.dataObject.recordSource.viewName,
                    appIdOverride: this.dataObject.offline.appIdOverride,
                    databaseIdOverride: this.dataObject.offline.databaseIdOverride,
                    objectStoreIdOverride: this.dataObject.offline.objectStoreIdOverride,
                    maxRecords: -1,
                    fields: []
                };

                const filterObject = this.changeFilterItems(([this.distinctTargetColumn, this.targetColumn, this.column]), this.dataObject.recordSource.getItemsClone());

                if (filterObject) {
                    options.filterObject = filterObject;
                }

                const whereObject = this.dataObject.offline.whereObject;

                if (whereObject) {
                    options.whereObject = whereObject;
                }

                const masterDetailObject = this.dataObject.masterDetails.getFilterObject();

                if (masterDetailObject) {
                    options.masterDetailObject = masterDetailObject;
                }

                if (this.dataObject.recordSource.contextId) {
                    options.contextId = this.getContextId();
                }

                const countField = this.getCountField();

                if (this.distinctTargetColumn) {
                    options["fields"]?.push({
                        name: this.distinctTargetColumn,
                        sortDirection: this.sortDirection,
                        sortOrder: 2,
                        groupByOrder: 1
                    })
                }

                if (this.targetColumn) {
                    options["fields"]?.push({
                        name: this.targetColumn,
                        sortDirection: this.sortOnCount ? null : this.sortDirection,
                        sortOrder: this.sortOnCount ? null : 2,
                        groupByOrder: 1
                    })
                }

                if (!options["fields"]?.find(x => x.name == this.distinctColumn ?? this.column)) {
                    options["fields"]?.push({
                        name: this.distinctColumn ?? this.column,
                        sortDirection: this.sortOnCount ? null : this.sortDirection,
                        sortOrder: this.sortOnCount ? null : 1,
                        groupByOrder: 1
                    })
                }

                options.fields?.push({
                    name: countField,
                    alias: "Count",
                    aggregate: this.dataObject.recordSource.distinctRows ? "COUNT_DISTINCT" : "COUNT",
                    sortDirection: this.sortOnCount ? this.sortDirection : null,
                    sortOrder: this.sortOnCount ? 1 : null
                });

                if (this.search) {
                    options.whereObject = {
                        type: 'group',
                        operator: 'and',
                        items: [
                            {
                                column: (this.column),
                                operator: "contains",
                                value: this.search,
                                type: "expression"
                            }
                        ]
                    }

                    if (whereObject) {
                        options.whereObject.items.push(whereObject);
                    }
                }

                return options;
            };
        }
    },
    getData: {
        get: function getData(this: DistinctHandler): Function {
            return (reload: boolean) => {
                if (!this.dataObject.shouldEnableOffline) {
                    return originalGetData(reload);
                }

                if (this.dataObject == null) {
                    return;
                }

                const options = this.getQueryOptions();

                return new Promise((resolve, reject) => {
                    this.dataObject.dataHandler.distinct(options).then((pData: Array<any>) => {
                        this.dataLoaded = true;

                        resolve(pData);
                    }).catch(ex => {
                        reject(ex);
                    });
                });
            };
        }
    }
})

const originalDataItemGetFilePath = Item.prototype.getFilePath;

Object.defineProperties(Item.prototype, {
    getFilePath: {
        get: function getFilePath(this: Item): Function {
            return (mode: 'view' | 'download' | 'view-pdf' | 'download-pdf', options?: {
                viewName?: string,
                primKey?: string,
                primKeyColumnName?: string,
                fileName?: string,
                fileNameColumnName?: string,
                queryString?: string,
            }): string => {
                const dataObjectId = this.dataObjectId;
                const dataObject = getDataObjectById(dataObjectId, app.id);
                const dataObjectOffline: DataObjectOffline = dataObject.offline;

                if (dataObjectOffline === null || dataObjectOffline.appStateOverride === 'ONLINE') {
                    return originalDataItemGetFilePath.call(this, mode, options);
                }

                const appIdOverride = dataObjectOffline?.appIdOverride;
                const databaseIdOverride = dataObjectOffline?.databaseIdOverride;
                const objectStoreIdOverride = dataObjectOffline?.objectStoreIdOverride;

                let { viewName, primKey, primKeyColumnName, fileName, fileNameColumnName, queryString } = options ?? {};

                viewName ??= getDataObjectById(this.dataObjectId, this.appId).viewName;
                primKey ??= primKeyColumnName ? this.item[primKeyColumnName] : this.item.PrimKey;
                fileName ??= fileNameColumnName ? this.item[fileNameColumnName] : this.item.FileName;

                let basePath = (() => {
                    switch (mode) {
                        case 'download':
                            return '/pwa/api/file/download';
                        case 'download-pdf':
                            return '/pwa/api/download-pdf';
                        case 'view':
                            return '/pwa/api/file/view';
                        case 'view-pdf':
                            return '/pwa/api/view-pdf';
                    }
                })();

                let url = `${basePath}/${appIdOverride ?? app.id}/${databaseIdOverride ?? dataObject.id}/${objectStoreIdOverride ?? viewName}/${primKey}`;

                if (fileName !== undefined && fileName.length > 0) {
                    // TODO: Move filename from path to querystring. Also need this support in o365.pwa.modules.sw.apiRequestOptions.ApiFileRequestOptions.ts
                    url += `/${fileName}?file-name=${encodeURIComponent(fileName)}`;
                }

                if (queryString !== undefined && queryString.length > 0) {
                    url += `${url.includes('?') ? '&' : '?'}${queryString}`;
                }

                return url;
            };
        }
    }
});

const originalSetRequestHeader = XMLHttpRequest.prototype.setRequestHeader;

Object.setPrototypeOf(XMLHttpRequest, {
    setRequestHeader: {
        get: function setRequestHeader(this: XMLHttpRequest): Function {
            return (name: string, value: string) => {
                if (name === 'X-O365-XHR') {
                    return;
                }

                originalSetRequestHeader.call(this, name, value);
            };
        }
    }
});

export class DataObjectOffline {
    private dataObject: DataObject;
    private dataHandlerRequest: Function;
    private getOriginalOptions: Function;
    private _hasOfflineChanges: Ref<Boolean> = ref(false);
    public appStateOverride?: AppState = "OFFLINE";

    public readonly shouldGenerateOfflineData: boolean;
    public readonly jsonDataVersion: number;
    public readonly appIdOverride?: string;
    public readonly databaseIdOverride?: string;
    public readonly objectStoreIdOverride?: string;
    public readonly generateOfflineDataViewNameOverride?: string;
    public readonly generateOfflineDataProcedureNameOverride?: string;
    public readonly propertiesDataObjectId?: string;
    public readonly itemIdFieldOverride?: string;
    public readonly bindingFieldOverride?: string;

    get hasOfflineChanges(): Ref<Boolean> {
        return this._hasOfflineChanges;
    }

    get indexedDBIndexes(): Array<IIndexedDBIndexConfig> {
        const dataObjectConfig = getDataObjectConfigById(this.dataObject.id, this.dataObject.appId);
        const fields = Array.from(dataObjectConfig?.fields ?? []);
        const offlineFieldConfigs = dataObjectConfig?.offline?.fieldConfig ?? {};
        const offlineFieldConfigsKeys = Array.from(Object.keys(offlineFieldConfigs));

        for (const offlineFieldConfigKey of offlineFieldConfigsKeys) {
            for (const fieldIndex in fields) {
                const field = fields[fieldIndex];

                if (field.name !== offlineFieldConfigKey) {
                    continue;
                }

                const offlineFieldConfig = offlineFieldConfigs[offlineFieldConfigKey];

                fields[fieldIndex] = Object.assign({}, field, offlineFieldConfig);
            }
        }

        const indexes = new Array<IIndexedDBIndexConfig>();

        if (this.shouldGenerateOfflineData) {
            indexes.push({
                id: 'PrimKey',
                keyPath: 'PrimKey',
                isUnique: true,
                isAutoIncrement: false,
                isMultiEntry: false,
                isPrimaryKey: true
            }, {
                id: 'O365_Status',
                keyPath: 'O365_Status',
                isUnique: false,
                isAutoIncrement: false,
                isMultiEntry: false,
                isPrimaryKey: false
            });
        }

        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];

            if (!field.pwaUseIndex) {
                continue;
            }

            const index = <IIndexedDBIndexConfig>{
                id: field.name,
                keyPath: field.name,
                isPrimaryKey: !!field.pwaIsPrimaryKey,
                isAutoIncrement: !!field.pwa,
                isUnique: !!field.pwaIsUnique,
                isMultiEntry: !!field.pwaIsMultiValue
            };

            if (field.pwaCompoundId) {
                const keyPath = index.keyPath as string;

                index.keyPath = [keyPath];

                for (let j = fields.length - 1; j > i; j--) {
                    const field2 = fields[j];

                    if (field.pwaCompoundId === field2.pwaCompoundId) {
                        index.id += field2.name;
                        index.keyPath.push(field2.name);

                        fields.splice(j, 1);
                    }
                }
            }

            indexes.push(index);
        }

        return indexes;
    }

    constructor(dataObject: DataObject) {
        this.dataObject = dataObject;
        const dataObjectConfig = getDataObjectConfigById(dataObject.id, dataObject.appId);

        this.shouldGenerateOfflineData = dataObject.shouldGenerateOfflineData;
        this.jsonDataVersion = dataObject.jsonDataVersion;
        this.appIdOverride = dataObject.appIdOverride;
        this.databaseIdOverride = dataObject.databaseIdOverride;
        this.objectStoreIdOverride = dataObject.objectStoreIdOverride;
        this.generateOfflineDataProcedureNameOverride = dataObject.generateOfflineDataProcedureNameOverride;
        this.propertiesDataObjectId = dataObjectConfig?.offline?.propertiesDataObjectId
        this.generateOfflineDataViewNameOverride = dataObject.generateOfflineDataViewNameOverride;
        this.itemIdFieldOverride = dataObjectConfig?.offline?.properties?.itemIdFieldOverride;
        this.bindingFieldOverride = dataObjectConfig?.offline?.properties?.bindingFieldOverride;


        const dataHandler = dataObject.dataHandler;

        if (!(dataHandler instanceof DataHandler)) {
            throw new Error('At the moment only DataHandler is supported');
        }

        this.dataHandlerRequest = dataHandler.request.bind(dataHandler);
        this.getOriginalOptions = dataObject.recordSource.getOptions.bind(this.dataObject.recordSource);

        dataHandler.request = this.request.bind(this);
        this.dataObject.recordSource.getOptions = this.getOptions.bind(this);

        if (this.shouldGenerateOfflineData) {
            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_PrimKey',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CCTL',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Created',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CreatedBy_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Updated',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_UpdatedBy_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_JsonData',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Type',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_ErrorMessage',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_Owner_ID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_LastCheckIn',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_AppID',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_JsonDataVersion',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_ExternalRef',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_CreatedBy',
            });

            this.dataObject.fields.addFieldIfNotExists({
                name: 'O365_UpdatedBy',
            });

            if (this.dataObject.fields['FileRef'] ?? false) {
                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileName',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileSize',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileUpdated',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'FileRef',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'Extension',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'CheckedOut',
                });

                this.dataObject.fields.addFieldIfNotExists({
                    name: 'CheckedOutBy_ID',
                });
            }
            this.getOfflineChanges()
        }

    }

    private async request<T extends IRequestOptions>(pType: RequestOperation, pData: T, pHeaders?: Headers, pOptions?: IOfflineRequestOptions) {
        const vHeaders = pHeaders ?? new Headers();
        const vOptions = Object.assign({}, defaultOfflineRequestOptions, pOptions ?? {});

        const idbApp = await IndexedDBHandler.getApp(app.id);
        const idbPwaState = await idbApp?.pwaState;

        if (idbPwaState) {
            const appStateOverride: AppState = vOptions.appStateOverride ?? this.appStateOverride ?? idbPwaState.appState;

            vHeaders.set('O365-App-State-Override', appStateOverride);
        }

        const response = await this.dataHandlerRequest.call(this, pType, pData, vHeaders, pOptions);

        if (pData.operation === "create" || pData.operation === "destroy" || pData.operation === "update") {
            this.getOfflineChanges();
        }

        return response;
    }

    public async getLocalRecordCount() {
        try {
            const dexieInstance = await IndexedDBHandler.getDexieInstance(this.appIdOverride ?? app.id, this.databaseIdOverride ?? "DEFAULT", this.objectStoreIdOverride ?? this.dataObject.id);
            if (!dexieInstance) {
                return;
            }
            let dexieCollection = dexieInstance;
            const recordCount = await dexieCollection.count();

            return recordCount;
        } catch (e) {
            console.error(e);
        }
        return;
    }

    public async getOfflineChanges() {
        try {
            if (!this.shouldGenerateOfflineData) {
                return false;
            }

            let status = "O365_Status";

            const dexieInstance = await IndexedDBHandler.getDexieInstance(this.appIdOverride ?? app.id, this.databaseIdOverride ?? "DEFAULT", this.objectStoreIdOverride ?? this.dataObject.id);
            if (!dexieInstance) {
                return;
            }
            let dexieCollection = dexieInstance;
            let data = await dexieCollection.where(status).anyOf(["UPDATED", "CREATED", "FILE-CREATED", "FILE-UPDATED"]).count();

            this._hasOfflineChanges = ref(data > 0);

            return this._hasOfflineChanges;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    private getOptions() {
        const options = this.getOriginalOptions();

        if (options.filterString && options.filterString === this.dataObject.recordSource.filterString) {
            console.warn(`PWA:: DataObject does not support filter string in PWA mode, use filter object. DataObject ID: ${this.dataObject.id}`);
        }

        if (options.whereClause && options.whereClause === this.dataObject.recordSource.whereClause) {
            console.warn(`PWA:: DataObject does not support where clause in PWA mode, use where object. DataObject ID: ${this.dataObject.id}`);
        }

        delete options.filterString;
        delete options.whereClause;
        delete options.masterDetailString;

        options.dataObjectId = this.dataObject.id;
        options.viewName = this.dataObject.viewName;
        options.uniqueTable = this.dataObject.uniqueTable;

        options.appIdOverride = this.appIdOverride;
        options.databaseIdOverride = this.databaseIdOverride;
        options.objectStoreIdOverride = this.objectStoreIdOverride;

        options.filterObject = this.dataObject.filterObject?.filterObject;
        options.whereObject = this.dataObject.whereObject;
        options.indexedDbWhereExpression = this.dataObject.indexedDbWhereExpression;
        options.masterDetailObject = this.dataObject.masterDetails?.getDevexObject();

        return options;
    }

}

Object.defineProperties(DataObject.prototype, {
    'propertiesData': {
        get() {
            if (this._propertiesData == null) {
                this._propertiesData = new OfflinePropertiesData(this);
                this.hasPropertiesData = true;
            }
            return this._propertiesData;
        },
        configurable: true
    },
});




export class OfflineDataObjectPropertiesHandler<T extends ItemModel> extends BasePropertiesDataHandler<T> {
    static propertyLookupValuesCache = new Map<string, { Value: any, SortOrder: number }[]>();

    $: {
        dataObject: DataObject<T>
    };

    private _bulkPropertiesRetrieve = new BulkOperation<number | string, DataItemModel<PropertyValueModel>[]>({
        bulkOperation: async (pQueue) => {
            const dataObject = this.$.dataObject as DataObjectWithProperites<T>;
            const selectedProperties = dataObject.propertiesData.selectedProperties;
            if (selectedProperties.length === 0) {
                for (const item of pQueue) {
                    item.res([]);
                }
                return;
            }

            const propertiesDataObject = dataObject.propertiesData.propertiesDataObject;

            const propertyField = dataObject.propertiesData.propertyField;
            if (selectedProperties.length > 1) {
                //propertyField IN (selectedProperties)
                propertiesDataObject.filterObject.setColumnExistsOptions(propertyField, {
                    targetColumn: selectedProperties
                })
            } else {
                propertiesDataObject.whereObject = {
                    value: selectedProperties[0],
                    valueType: "string",
                    operator: "equals",
                    column: propertyField,
                    type: "expression"
                }
            }


            // const filterString = pQueue.length == 1
            //     ? `${dataObject.propertiesData.propertyIdField} = '${pQueue[0].value}'`
            //     : `${dataObject.propertiesData.propertyIdField} IN (${pQueue.map(x => `'${x.value}'`).join(',')})`;

            let data: DataItemModel[];
            await propertiesDataObject.load();

            try {
                data = propertiesDataObject.data;
            } catch (ex) {
                for (const row of pQueue) {
                    row.rej(ex);
                }
                return;
            }

            for (const row of pQueue) {
                const rows = data.filter(x => x[dataObject.propertiesData.propertyIdField] === row.value) as DataItemModel<PropertyValueModel>[];
                row.res(rows);
            }
        }
    });

    private _bulkPropertyRetrieve = new BulkOperation<{ id: string | number, property: string }, DataItemModel<PropertyValueModel> | undefined>({
        bulkOperation: async (pQueue) => {
            const dataObject = this.$.dataObject as DataObjectWithProperites<T>;

            const propertyNames = new Set<string>();
            const itemIds = new Set<string | number>();
            for (const item of pQueue) {
                propertyNames.add(item.value.property);
                itemIds.add(item.value.id);
            }

            const propertiesDataObject = dataObject.propertiesData.propertiesDataObject;

            const propertyField = dataObject.propertiesData.propertyField;
            if (propertyNames.size > 1) {
                //propertyField IN (selectedProperties)
                propertiesDataObject.filterObject.setColumnExistsOptions(propertyField, {
                    targetColumn: Array.from(propertyNames)
                })
            } else {
                propertiesDataObject.whereObject = {
                    value: Array.from(propertyNames)[0],
                    valueType: "string",
                    operator: "equals",
                    column: propertyField,
                    type: "expression"
                }
            }

            // const filterString = pQueue.length == 1
            //     ? `${dataObject.propertiesData.propertyIdField} = '${Array.from(itemIds)[0]}'`
            //     : `${dataObject.propertiesData.propertyIdField} IN (${Array.from(itemIds).map(x => `'${x}'`).join(',')})`;

            let data: DataItemModel<PropertyValueModel>[];

            try {
                await propertiesDataObject.load();
                data = propertiesDataObject.data as any;
            } catch (ex) {
                for (const row of pQueue) {
                    row.rej(ex);
                }
                return;
            }
            for (const row of pQueue) {
                const propertyRow = data.find(x => {
                    return (x as any)[dataObject.propertiesData.propertyIdField] == row.value.id
                        && (x as any)[dataObject.propertiesData.propertyField] == row.value.property;
                });
                row.res(propertyRow);
            }
        }
    })

    constructor(pDataObject: DataObject<T>) {
        super();

        this.$ = markRaw({}) as any;
        this.$.dataObject = pDataObject;
    }

    inputEditorComponents = markRaw({
        objectLookup: ObjectsLookup,
        orgUnitLookup: OrgUnitsLookup
    })
    async getSelectedProperties(pOptions?: {
        onSelected?: (pProperty: SelectableProperty) => void;
        onUnSelected?: (pProperty: SelectableProperty) => void;
    }) {
        const dataObject = this.$.dataObject as DataObjectWithProperites<T>;
        if (dataObject.propertiesData == null) { return; }

        const selectableProperties = getDataObjectById("dsO365_OFFLINE_PropertiesEditorsWithBinding", app.id);

        selectableProperties.whereObject = {
            value: dataObject.propertiesData.configView,
            valueType: "string",
            operator: "equals",
            column: "ViewName",
            type: "expression"
        }

        // let whereClause = `exists_clause(sviw_System_PropertiesViews, T2.[PropertyName] = T1.[Name], [ViewName] = '${dataObject.propertiesData.configView}')`;

        await selectableProperties.load();

        return selectableProperties.data.map((item: any) => new SelectableProperty(item, {
            isInSelectedList: (pName: string) => dataObject.propertiesData.selectedProperties.includes(pName),
            onSelected: (pProperty) => {
                dataObject.propertiesData.addProperty(pProperty);
                if (pOptions?.onSelected) {
                    pOptions.onSelected(pProperty);
                }
            },
            onUnSelected: (pProperty) => {
                if (pOptions?.onUnSelected) {
                    pOptions.onUnSelected(pProperty);
                }
                dataObject.propertiesData.removeProperty(pProperty.Name);
            },
        }));
    }
    async getExistingPropertiesForItem(pItem: DataItemModel<T>, pLoadAll?: boolean) {
        const dataObject = this.$.dataObject as DataObjectWithProperites<T>;

        if (dataObject == null || !dataObject.hasPropertiesData || pItem == null) { return; }

        if (!pLoadAll && pItem[dataObject.propertiesData.itemIdField] == null) {
            logger.warn('Cannot load properties for item: missing binding field value', pItem)
            return;
        }

        const selectableProperties = getDataObjectById("dsO365_OFFLINE_PropertiesEditorsWithBinding", app.id);
        selectableProperties.whereObject = {
            value: dataObject.propertiesData._configView,
            valueType: "string",
            operator: "equals",
            column: "ViewName",
            type: "expression"
        }
        await selectableProperties.load();
        let properties = [];

        if (pLoadAll) {
            properties = selectableProperties.data;
        } else {
            const propertyDataObject = dataObject.propertiesData.propertiesDataObject;
            propertyDataObject.whereObject = {
                value: pItem[dataObject.propertiesData.itemIdField],
                valueType: "string",
                operator: "equals",
                column: dataObject.propertiesData.propertyIdField,
                type: "expression"
            }

            await propertyDataObject.load();

            const propertyNames = dataObject.propertiesData.propertiesDataObject.data.map((property: any) => property.PropertyName);

            properties = selectableProperties.data.filter((property: any) => propertyNames.includes(property.Name));
        }


        //`exists_clause(sviw_System_PropertiesViews, T2.[PropertyName] = T1.[Name], [ViewName] = '${dataObject.viewName}')`

        return properties;
    }
    /** Get all connected properties for the given view. */
    async getConnectedPropertiesForView() {
        const dataObject = this.$.dataObject as DataObjectWithProperites<T>;

        if (dataObject == null || !dataObject.hasPropertiesData) { return []; }

        const dsPropertiesEditorsWithBinding = getDataObjectById("dsO365_OFFLINE_PropertiesEditorsWithBinding", app.id);

        dsPropertiesEditorsWithBinding.whereObject = {
            value: dataObject.propertiesData.configView,
            valueType: "string",
            operator: "equals",
            column: "ViewName",
            type: "expression"
        }

        await dsPropertiesEditorsWithBinding.load();
        return dsPropertiesEditorsWithBinding.data;
    }
    async getPropertiesDefinitions(pProperties: number[]) {
        const selectableProperties = getDataObjectById("dsO365_OFFLINE_PropertiesEditorsWithBinding", app.id);
        selectableProperties.indexedDbWhereExpression = {
            type: 'equals',
            keyPath: 'ID',
            key: pProperties
        };

        await selectableProperties.load();

        return selectableProperties;
    }
    async getViewConfiguration(pViewName: string) {
        const dsViewConfiguration = getDataObjectById("dsO365_OFFLINE_PropertiesEditorsWithBinding", app.id);
        if (dsViewConfiguration) {
            dsViewConfiguration.whereObject = {
                value: pViewName,
                valueType: "string",
                operator: "equals",
                column: "ViewName",
                type: "expression"
            }
            await dsViewConfiguration.load();

            if (dsViewConfiguration.data[0]) {
                const { PropertyViewName, PropertyUniqueTableName, PropertyBinding } = dsViewConfiguration.data[0];
                const { bindingFieldOverride, itemIdFieldOverride } = this.$.dataObject.offline || {};

                const updatedPropertyBinding = bindingFieldOverride && itemIdFieldOverride
                    ? `${bindingFieldOverride} = ${itemIdFieldOverride}`
                    : PropertyBinding;

                return {
                    PropertyViewName,
                    PropertyUniqueTableName,
                    PropertyBinding: updatedPropertyBinding,
                };
            }
        }
        return undefined;
    }
    getPropertiesDataObject(pOptions: {
        viewName: string
        fields: NonNullable<RecordSourceOptions['fields']>,
        uniqueTable?: string,
        definitionProc?: string
    }) {
        const dataObject = this.$.dataObject as DataObjectWithProperites<T>;
        if (!dataObject?.offline?.propertiesDataObjectId) {
            logger.warn('Data object does not have a properties data object binding.');
        }

        const propertiesDataObject = getDataObjectById(dataObject.offline?.propertiesDataObjectId, app.id);
        return propertiesDataObject;
    }
    async bulkRetrievePropertiesForItem(pId: string | number) {
        const properties = await this._bulkPropertiesRetrieve.addToQueue(pId);
        return properties ?? [];
    }
    async retrievePropertiesForSingleItem(pId: string | number) {
        if (pId == null) { return []; }
        const dataObject = this.$.dataObject as DataObjectWithProperites<T>;
        const propertiesDataObject = dataObject.propertiesData.propertiesDataObject;
        const selectedProperties = dataObject.propertiesData.selectedProperties;
        if (selectedProperties.length === 0) { return []; }


        propertiesDataObject.whereObject = {
            value: pId,
            valueType: "string",
            operator: "equals",
            column: dataObject.propertiesData.propertyIdField,
            type: "expression"
        }

        await propertiesDataObject.load();

        return propertiesDataObject.data as DataItemModel<PropertyValueModel>[];
    }
    /** Retrieve a property for an item in a bulk operation  */
    async bulkRetrievePropertyForItem(pId: string | number, pProperty: string) {
        const row = await this._bulkPropertyRetrieve.addToQueue({
            id: pId,
            property: pProperty
        });
        return row;
    }
    //#region Property Editor

    /** Get local lookup values (from stbl_System_PropertiesValues) for a property */
    async getPropertyLookupValues(pProperty: string, pContextId?: number) {
        const key = `${pProperty}-${pContextId || 'nocontext'}`
        const propertyLookupValuesCache = OfflineDataObjectPropertiesHandler.propertyLookupValuesCache;

        if (propertyLookupValuesCache.has(key)) {
            return propertyLookupValuesCache.get(key)!;
        }
        const propertiesDataObject = this.$.dataObject.propertiesData.propertiesDataObject;
        if (!(propertiesDataObject.state.isLoaded)) {
            await propertiesDataObject.load();
        }

        const data = propertiesDataObject.data.filter((row: any) => row.PropertyName === pProperty)[0].LookupValues;

        const sortedValues = data.sort((a: { Value: any, SortOrder: number }, b: { Value: any, SortOrder: number }) => a.SortOrder - b.SortOrder);

        propertyLookupValuesCache.set(pProperty, sortedValues);
        return sortedValues;
    }
    getDataObjectForInputEdior(pConfig: NonNullable<PropertiesDefintion['inputEditor']>, pPropertyConfig: PropertiesDefintion) {
        if (pConfig.ViewName == null || pConfig.Columns == null) { return undefined; }

        let id = `dsO365_OFFLINE_PropertiesLookup_${pConfig.ViewName}`;
        const dataObject = getDataObjectById(id, app.id);

        return dataObject;
    }
    getWhereClauseForInputEditor(pItem: PropertiesEditor) {
        const whereObject = pItem.config.$.propertyModel.WhereObject;
        if (whereObject) {
            return whereObject;
        }
        return null;
    }
}

class OfflinePropertiesData<T extends ItemModel = ItemModel> extends PropertiesData {
    constructor(pDataObject: DataObject) {
        super(pDataObject);
        this._propertiesHandler = new OfflineDataObjectPropertiesHandler(pDataObject);
    }
}

export default DataObjectOffline;
